const transferBtnData = [
  {
    id: "1",
    title: "Skarbowy",
    subtitle: "Przelew",
    route: "/tax-transfer",
    icon: "mdi mdi-bank-outline",
    color: "light",
    footerLinkText: "Złóż wniosek",
  },
  {
    id: "2",
    title: "Krajowy",
    subtitle: "Przelew",
    route: "/internal-transfer",
    icon: "mdi mdi-cash-100",
    color: "light",
    footerLinkText: "Złóż wniosek",
  },
  {
    id: "3",
    title: "Zagraniczny",
    subtitle: "Przelew",
    route: "/international-transfer",
    icon: "mdi mdi-currency-eur",
    color: "light",
    footerLinkText: "Złóż wniosek",
  },
  {
    id: "4",
    title: "Karty",
    subtitle: "Zasilenie",
    route: "/prepaid-card-transfer",
    icon: "mdi mdi-transfer",
    color: "light",
    footerLinkText: "Złóż wniosek",
  },
];

export { transferBtnData }
