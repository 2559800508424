const dateShortcutsRange = [
  {
    text: 'Dzisiaj',
    onClick() {
      const start = new Date()
      const end = new Date()
      const date = [start, end]
      return date
    },
  },
  {
    text: 'Wczoraj',
    onClick() {
      const start = new Date()
      const end = new Date()
      start.setTime(start.getTime() - 24 * 3600 * 1000)
      end.setTime(end.getTime() - 24 * 3600 * 1000)
      const date = [start, end]
      return date
    },
  },
  {
    text: 'Jutro',
    onClick() {
      const start = new Date()
      const end = new Date()
      start.setTime(start.getTime() + 24 * 3600 * 1000)
      end.setTime(end.getTime() + 24 * 3600 * 1000)
      const date = [start, end]
      return date
    },
  },
  {
    text: 'Tydzień',
    onClick() {
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime() + 7 * 24 * 3600 * 1000)
      const date = [start, end]
      return date
    },
  },
]

const dateShortcutsPoint = [
  {
    text: 'Dzisiaj',
    onClick() {
      const date = new Date()
      return date
    },
  },
  {
    text: 'Jutro',
    onClick() {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24)
      return date
    },
  },
  {
    text: 'Za tydzień',
    onClick() {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
      return date
    },
  },
]

export { dateShortcutsRange, dateShortcutsPoint }
