<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import Repository from "@/app/repository/repository-factory";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import { roleService } from "@/app/service/roleService";
import { appService } from "@/app/service/appService";
import { typeOfApplications } from "@/data/data-type-of-application-status";
import { dateShortcutsRange } from "@/components/data/dateShortcuts";
import { transferBtnData } from "@/components/data/transferBtnData";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");

export default {
  page: {
    title: "Strona główna",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      selectedItems: [],
      selectOrDeselectAll: false,
      barvalue: 0,
      timeOne: this.setTimeClock(),
      statData: transferBtnData,
      filters: {
        status: [],
        companies: [],
        typeOfApplication: [],
        bank: [],
        dateRange: [],
        grid: {
          filter: "",
          filterOn: [""],
          perPage: 500,
          sortBy: "createdAt",
          sortDesc: true,
          fieldsSelected: [],
        },
      },
      grid: {
        totalRows: 0,
        currentPage: 1,
        perPage: 500,
        pageOptions: [10, 25, 50, 100, 500],
        filter: "",
        filterOn: [""],
        sortBy: "createdAt",
        sortDesc: true,
        fields: [],
      },
      fieldsSelectOptions: [],
      companySelectOptions: [],
      bankSelectOptions: [],
      typeOfApplicationSelectOptions: typeOfApplications,
      reportArray: [],
      dateShortcutsRange: dateShortcutsRange,
    };
  },
  created() {
    this.getStateGridFilters();
    this.getCompanies();
    this.getBank();
  },
  mounted: function () {
    setInterval(() => {
      this.timeOne = this.setTimeClock();
    }, 1000);
  },
  methods: {
    onToggleRow(index, rowSelected) {
      !rowSelected
        ? this.$refs.table_account.unselectRow(index)
        : this.$refs.table_account.selectRow(index);
    },
    async nextProgression(total) {
      this.barvalue += 100 / total;
    },
    async sendMultiAppAccept() {
      try {
        if (this.selectedItems.length > 0) {
          this.barvalue = 0.01;
          for (const si of this.selectedItems) {
            let payload = {
              applicationStatus: 3,
              version: si.version,
            };
            const promise = await this.apiGo(
              payload,
              si.applicationId,
              si.applicationNumber,
              this.selectedItems.length
            );
          }
          this.$refs.table_account.refresh();
        } else {
          this.$notify({
            title: "Błąd!",
            dangerouslyUseHTMLString: true,
            message: "Brak zaznaczonych wniosków!",
            type: "error",
            duration: 2500,
            position: "top-right",
          });
        }
      } catch (error) {
        console.log(error);
      }
      await setTimeout(() => {
        this.barvalue = 0;
        this.selectedItems = [];
      }, 1000);
    },
    async sendMultiAppDiscard() {
      try {
        if (this.selectedItems.length > 0) {
          this.barvalue = 0.01;
          Swal.fire({
            title: "Podaj przyczynę odrzucenia wniosków",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "Odrzuć wnioski",
            cancelButtonText: "Anuluj",
            showLoaderOnConfirm: true,
            confirmButtonColor: "#61aa3d",
            cancelButtonColor: "#e2001a",
            inputValidator: (reason) => {
              if (!reason) {
                return "Podaj przyczynę odrzucenia!";
              }
            },
            preConfirm: async (reason) => {
              for (const si of this.selectedItems) {
                let payload = {
                  applicationStatus: 2,
                  statusMessage: reason,
                  version: si.version,
                };
                const promise = await this.apiGo(
                  payload,
                  si.applicationId,
                  si.applicationNumber,
                  this.selectedItems.length
                );
              }
              await setTimeout(() => {
                this.$refs.table_account.refresh();
                this.barvalue = 0;
                this.selectedItems = [];
              }, 1000);
            },
            allowOutsideClick: false,
          }).then((reason) => {
            if (reason.value) {
              "SUKCES!", "Wnioski zostały odrzucone!", "success";
            } else if (reason.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Anulowano", "Wnioski nie zostały odrzucone!", "error");
            }
          });
        } else {
          this.$notify({
            title: "Błąd!",
            dangerouslyUseHTMLString: true,
            message: "Brak zaznaczonych wniosków!",
            type: "error",
            duration: 2500,
            position: "top-right",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async apiGo(payload, applicationId, appnumber, totalitems) {
      try {
        await PaymentApplicationRepository.changeStatus(payload, applicationId)
          .then(() => {
            this.$notify({
              title: "Sukces!",
              dangerouslyUseHTMLString: true,
              message:
                appnumber +
                " został " +
                (payload.applicationStatus === 3
                  ? "zaakceptowany"
                  : "odrzucony") +
                "!",
              type: "success",
              duration: 2500,
              position: "top-right",
            });
          })
          .catch((error) => {
            this.$notify({
              title:
                "Błąd " +
                (payload.applicationStatus === 3
                  ? "akceptacji wniosku"
                  : "odrzucenia wniosku"),
              dangerouslyUseHTMLString: true,
              message:
                "Wystąpił błąd z " +
                appnumber +
                "<br/>" +
                error.response.data.message,
              type: "error",
              duration: 2500,
              position: "top-right",
            });
          });
        await this.nextProgression(totalitems);
      } catch (error) {
        console.log(error);
      }
    },
    fieldsComputed(fieldList) {
      let fieldsComputed = [];
      fieldsComputed.push({
        key: "selected",
        label: "#",
      });
      let fieldsSelected = [];
      const selectedCount = this.filters.grid.fieldsSelected.length;
      const defaultField = [
        "applicationNumber",
        "typeOfApplication",
        "nameOfRecipient",
        "applicant",
        "transfer",
        "companyBusinessUnitCode",
        "createdAt",
      ];

      // If selected is empty (eg. no save state)
      if (selectedCount === 0) {
        Object.entries(fieldList).forEach(([key, val]) => {
          if (defaultField.includes(val.key)) {
            fieldsSelected.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });

            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        });

        this.filters.grid.fieldsSelected = fieldsSelected;
      }

      // If selected is not empty
      if (selectedCount > 0) {
        Object.entries(this.filters.grid.fieldsSelected).forEach(
          ([key, val]) => {
            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        );
      }

      fieldsComputed.push({ key: "action", label: "Akcje", thClass: "last-col-sticky", tdClass: "last-col-sticky" });

      this.grid.fields = fieldsComputed;
    },
    getCompanies() {
      PaymentCompanyRepository.getAll()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].businessUnitCode,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBank() {
      PaymentBankRepository.getAll()
        .then((response) => {
          let bank = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            bank.push({
              text: response.data[i].name,
              value: response.data[i].shortName,
            });
          }

          this.bankSelectOptions = bank;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gridProvider(ctx) {
      // if (localStorage.getItem("gridMyApplicationsPaginationState") !== null) {
      //   ctx.currentPage = JSON.parse(
      //     localStorage.getItem("gridMyApplicationsPaginationState")
      //   );
      // }

      // if (localStorage.getItem("gridMyApplicationsSearchState") !== null) {
      //   this.filters.grid.filter = JSON.parse(
      //     localStorage.getItem("gridMyApplicationsSearchState")
      //   );
      // }

      if (this.filters.dateRange.length > 0) {
        this.apiRange =
          "&startDate=" +
          this.filters.dateRange[0] +
          "&endDate=" +
          this.filters.dateRange[1];
      } else {
        this.apiRange = "";
      }

      const parameters =
        "?page=" +
        ctx.currentPage +
        "&maxItems=" +
        ctx.perPage +
        "&search=" +
        this.filters.grid.filter +
        "&orderBy=" +
        this.filters.grid.sortBy +
        "&orderDesc=" +
        this.filters.grid.sortDesc +
        this.apiRange +
        "&filterTypeOfApplication=" +
        this.filters.typeOfApplication.map((e) => e.value).join(",") +
        "&filterStatus=Potwierdzone przez wnioskującego,Zaakceptowane - czekające na realizację,W trakcie realizacji,Zaakceptowane przez akceptującego wyższego rzędu" +
        "&filterBank=" +
        this.filters.bank.map((e) => e.value).join(",") +
        "&filterCompany=" +
        this.filters.companies.map((e) => e.value).join(",");
      const promise = PaymentApplicationRepository.getGrid(parameters);

      return promise
        .then((data) => {
          const fields = data.data.info.fields;
          this.grid.totalRows = data.data.info.countAllItems;

          // if (localStorage.getItem("gridMyApplicationsPaginationState") !== null) {
          //   this.grid.currentPage = JSON.parse(
          //     localStorage.getItem("gridMyApplicationsPaginationState")
          //   );
          // }

          let fieldsToSelect = [];

          Object.entries(fields).forEach(([key, val]) => {
            if (val.disabled !== true) {
              fieldsToSelect.push({
                text: val.label,
                value: key,
                label: val.label,
                key: key,
                sortable: val.sortable,
              });
            }
          });

          this.fieldsSelectOptions = fieldsToSelect;
          this.fieldsComputed(fieldsToSelect);

          this.reportArray = data.data.data || [];

          return this.reportArray;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    compareUserToApplier(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a);
    },
    checkColor(s) {
      return appService.checkAppColor(s);
    },
    setTimeClock() {
      return appService.getTimeClock();
    },
    clickSelectOrDeselectAll() {
      if (this.selectOrDeselectAll) {
        this.$refs.table_account.selectAllRows();
      } else {
        this.$refs.table_account.clearSelected();
      }
    },
    onRowSelected(items) {
      this.selectedItems = items;
    },
    refreshGrid(value, id) {
      this.$refs.table_account.refresh();
    },
    clearGridFiltersAndCols() {
      localStorage.removeItem("gridMyApplicationsPaginationState");
      localStorage.removeItem("gridMyApplicationsSearchState");
      this.grid.currentPage = 1;
      this.filters.status = [];
      this.filters.companies = [];
      this.filters.typeOfApplication = [];
      this.filters.bank = [];
      this.filters.dateRange = [];
      this.filters.grid.filter = "";
      this.filters.grid.filterOn = [""];
      this.filters.grid.perPage = 500;
      this.filters.grid.sortBy = "createdAt";
      this.filters.grid.sortDesc = true;
      this.filters.grid.fieldsSelected = [];
      this.saveStateGridFilters();
    },
    clearGridFilters() {
      this.grid.currentPage = 1;
      this.filters.status = [];
      this.filters.companies = [];
      this.filters.typeOfApplication = [];
      this.filters.bank = [];
      this.filters.dateRange = [];
      this.$refs.table_account.refresh();
    },
    saveStateGridFilters() {
      localStorage.setItem(
        "gridMyApplicationsState",
        JSON.stringify(this.filters)
      );
      this.$refs.table_account.refresh();

      Vue.swal({
        icon: "success",
        toast: false,
        position: "top",
        title: "SUKCES!",
        text: "Filtry zostały zapisane!",
        showConfirmButton: false,
        timer: 3500,
      });
    },
    getStateGridFilters() {
      if (localStorage.getItem("gridMyApplicationsState") !== null) {
        this.filters = JSON.parse(localStorage.getItem("gridMyApplicationsState"));
      }
    },
    clearAllStatus() {
      this.filters.status = [];
      this.$refs.table_account.refresh();
    },
    clearAllCompanies() {
      this.filters.companies = [];
      this.$refs.table_account.refresh();
    },
    clearAllTypeOfApplication() {
      this.filters.typeOfApplication = [];
      this.$refs.table_account.refresh();
    },
    clearAllBank() {
      this.filters.bank = [];
      this.$refs.table_account.refresh();
    },
    clearAllDateRange() {
      this.filters.dateRange = [];
      this.$refs.table_account.refresh();
    },
  },
  computed: {
    totalFiltersLength() {
      let f1 = this.filters.status ? this.filters.status.length : 0;
      let f2 = this.filters.companies ? this.filters.companies.length : 0;
      let f3 = this.filters.typeOfApplication ? this.filters.typeOfApplication.length : 0;
      let f4 = this.filters.bank ? this.filters.bank.length : 0;
      let f5 = this.filters.dateRange && this.filters.dateRange.length > 0 ? 1 : 0
      return f1 + f2 + f3 + f4 + f5;
    },
    isAccepting() {
      return roleService.isAccepting();
    },
    isAcceptingCard() {
      return roleService.isAcceptingCard();
    },
    isApplicant() {
      return roleService.isApplicant();
    },
  },
  watch: {
    // "grid.currentPage": function () {
    //   localStorage.setItem(
    //     "gridMyApplicationsPaginationState",
    //     JSON.stringify(this.grid.currentPage)
    //   );
    // },
    // "grid.filter": function () {
    //   localStorage.setItem(
    //     "gridMyApplicationsSearchState",
    //     JSON.stringify(this.grid.filter)
    //   );
    // },
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Strona główna</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Witaj w SI-PAY</li>
          </ol>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        {{ this.timeOne }}
      </div>
    </div>
    <!-- end page title -->

    <div class="row" v-if="isApplicant || isAccepting || isAcceptingCard">
      <div class="col-12">

        <b-collapse id="collapse">
          <b-card class="shadow">
            <div class="font-weight-bold" slot="header">Ustawienia kolumn i filtrów</div>
            <div class="form-group">
              <label class="typo__label">Wybierz widoczne kolumny tabeli</label>
              <multiselect v-model="filters.grid.fieldsSelected" :multiple="true" :options="fieldsSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz kolumny" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @input="fieldsComputed(fieldsSelectOptions)">
                <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
              </multiselect>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <div class="form-group">
                  <label class="typo__label">Zakres dat operacji</label>
                  <date-picker v-model="filters.dateRange" :shortcuts="dateShortcutsRange" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" lang="pl" placeholder="YYYY-MM-DD ~ YYYY-MM-DD" range @close="refreshGrid" @clear="clearAllDateRange" />
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Rodzaj wniosku</label>
                  <multiselect v-model="filters.typeOfApplication" :multiple="true" :options="typeOfApplicationSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz typ wniosku" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych wniosków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.typeOfApplication.length" @mousedown.prevent.stop="clearAllTypeOfApplication(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Wybierz firmę</label>
                  <multiselect v-model="filters.companies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz firmę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych firm: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.companies.length" @mousedown.prevent.stop="clearAllCompanies(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Wybierz bank</label>
                  <multiselect v-model="filters.bank" :multiple="true" :options="bankSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz bank" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych banków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.bank.length" @mousedown.prevent.stop="clearAllBank(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <button type="button" class="btn btn-danger waves-effect waves-light mr-2" v-on:click="clearGridFiltersAndCols">
                  <i class="mdi mdi mdi-trash-can-outline mr-1" /> Usuń ustawienia
                </button>
                <button type="button" class="btn btn-dark waves-effect waves-light mr-2" v-on:click="saveStateGridFilters">
                  <i class="dripicons-pin mr-1" /> Zapisz ustawienia
                </button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-warning waves-effect waves-light" v-on:click="clearGridFilters">
                  <i class="mdi mdi-filter-remove-outline mr-1" /> Wyczyść filtry
                </button>
              </div>
            </div>
          </b-card>
        </b-collapse>

        <div class="card shadow">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <div>
                <span class="font-weight-bold">Wnioski w trakcie procesu</span>
              </div>
              <div>
                <i class="mdi mdi-format-columns mr-1"></i>Kolumny: <span class="badge badge-pill badge-soft-info font-size-12">{{ filters.grid.fieldsSelected.length + '/' + fieldsSelectOptions.length}}</span>
                <span class="ml-2" v-if="totalFiltersLength > 0"><i class="mdi mdi-filter-outline mr-1"></i>Aktywne filtry: <span class="badge badge-pill badge-soft-success font-size-12">{{ totalFiltersLength }}</span></span>
              </div>
            </div>
            <b-progress v-if="barvalue > 0" :value="barvalue" :max="100" variant="warning" animated class="mt-1"></b-progress>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form inline>
                  <b-form-select v-model="filters.grid.perPage" :options="grid.pageOptions" class="w-auto" />
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.grid.filter" :debounce="350" placeholder="Szukaj..." :class="filters.grid.filter !='' ? 'border-success' : ''" />
                      <i class="ti-search search-icon" />
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="col-md-6 mt-2 mt-md-0">
                <div class="text-right mt-2 mt-md-0 mb-2" v-if="isAccepting || isAcceptingCard">
                  <button type="button" class="btn btn-dark waves-effect waves-light mb-2 mr-2" v-on:click="sendMultiAppAccept">
                    <i class="mdi mdi-file-document-box-check-outline mr-1" /> Zaakceptuj wnioski
                  </button>
                  <button type="button" class="btn btn-danger waves-effect waves-light mb-2 mr-2" v-on:click="sendMultiAppDiscard">
                    <i class="mdi mdi-file-document-box-remove-outline mr-1" /> Odrzuć wnioski
                  </button>
                  <b-button v-b-toggle="'collapse'" class="waves-effect mb-2 collapsed" variant="success">
                    <i class="mdi mdi-filter-outline mr-1"></i>Filtry
                  </b-button>
                </div>
              </div>
            </div>
            <!-- End search -->

            <!-- Table -->
            <div class="table-responsive mb-0">

              <b-table id="table_account" ref="table_account" selectable :items="gridProvider" :fields="grid.fields" responsive="sm" sticky-header="45vh" sort-icon-left :per-page="filters.grid.perPage" :current-page="grid.currentPage" :sort-by.sync="filters.grid.sortBy" :sort-desc.sync="filters.grid.sortDesc" :filter="filters.grid.filter" :filter-included-fields="filters.grid.filterOn" @row-selected="onRowSelected">
                <template #head(selected) v-if="isAccepting || isAcceptingCard">
                  <b-form-checkbox v-model="selectOrDeselectAll" @change="clickSelectOrDeselectAll" class="on-toggle-row-checkbox" />
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black">
                    <b-spinner class="align-middle" />
                    <strong class="ml-3">Ładowanie danych...</strong>
                  </div>
                </template>

                <!-- <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.fix"
                    @input="onPreviewClick($event, row.index, row.item)"
                  />
                </template> -->

                <!-- <template #cell(selected)="{ rowSelected }">
                    <b-form-checkbox v-model="rowSelected" @input="onToggleRow(row.index, rowSelected)" />
                </template> -->

                <template v-slot:cell(selected)="data" v-if="isAccepting || isAcceptingCard">
                  <b-form-checkbox v-model="data.rowSelected" @change="onToggleRow(data.index, data.rowSelected)" />
                </template>

                <template v-slot:cell(grossAmount)="data">
                  <span class="font-weight-bold float-right text-nowrap">{{ data.value | currencyFilter }}</span>
                </template>

                <template v-slot:cell(currency)="data">
                  <span class="text-nowrap">{{ data.value }}</span>
                </template>

                <!-- typeOftransfer in one column with type of application for internal transfer -->
                <template v-slot:cell(typeOfApplication)="row">
                  <span class="text-nowrap">
                    {{ row.item.typeOfApplication }}
                    <template v-if="row.item.typeOfApplication == 'Przelew krajowy'">
                      <br />
                      <small class="text-muted">{{ row.item.typeOfTransfer }}</small>
                    </template>
                    <template v-if="row.item.typeOfApplication == 'Przelew skarbowy'">
                      <br />
                      <small class="text-muted">{{ row.item.applicationSpecificData.isRecurring ? 'Cykliczny' : 'Jednorazowy' }}</small>
                    </template>
                    <template v-if="row.item.typeOfApplication == 'Zasilenie karty'">
                      <br />
                      <small class="text-muted">{{ row.item.applicationSpecificData.prepaidCardSettlement.isChecked ? 'Rozliczenie sprawdzone' : 'Rozliczenie niesprawdzone' }}</small>
                    </template>
                  </span>
                </template>

                <template v-slot:cell(applicationStatus)="data">
                  <span class="badge font-size-12" :class="`badge-soft-${checkColor(data.value)}`">{{ data.value }}</span>
                </template>

                <template v-slot:cell(createdAt)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <template v-slot:cell(acceptorDate)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <template v-slot:cell(action)="row">
                  <ul class="list-inline font-size-20 contact-links mb-0 text-nowrap">
                    <li class="list-inline-item px-2">
                      <a v-b-tooltip.hover.topleft title="Szczegóły wniosku" class="grid-action-btn">
                        <b-link :to="{ name: 'applications-details', params: { uuid: row.item.applicationId } }">
                          <i class="font-size-14 far fa-file-alt text-info" />
                        </b-link>
                      </a>
                    </li>
                    <li class="list-inline-item px-2" v-if="compareUserToApplier(row.item.applicantUserAccountId) && checkStatus(row.item.applicationStatus, [1,8])">
                      <a v-b-tooltip.hover.topleft title="Zmiana akceptującego" class="grid-action-btn">
                        <b-link :to="{ name: 'acceptor-change', params: { uuid: row.item.applicationId } }">
                          <i class="font-size-14 mdi mdi-account-convert text-info" />
                        </b-link>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col">
                <template v-if="grid.totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="grid.totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                    Znaleziono {{ grid.totalRows }}
                    rekordów.
                  </div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-if="grid.totalRows > 0" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="filters.grid.perPage" first-number last-number />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <b-alert variant="danger" class="mb-3" show fade>
          <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Strona, której szukasz nie została odnaleziona lub nie masz uprawnień do tego zasobu. Sprawdź poprawność adresu lub skontaktuj się z administratorem.
        </b-alert>
      </div>
    </div>
  </Layout>
</template>